import { useScript } from "@sphtech/web2-core/hooks";
import MetaTags from "@src/app/components/Blocks/MetaTags/MetaTags";
import MetaTagsArticle from "@src/app/components/Blocks/MetaTagsArticle/MetaTagsArticle";
import NotFound from "@src/app/components/Blocks/NotFound/NotFound";
import AdSettings from "@src/app/components/Elements/Advertisement/AdSettings";
import CatfishAd from "@src/app/components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@src/app/components/Elements/Advertisement/helpers";
import PrestitialAd from "@src/app/components/Elements/Advertisement/PrestitialAd";
import GAData from "@src/app/components/Elements/GAData/GAData";
import ArticleContent, {
  IArticleContent,
} from "@src/app/components/Modules/ArticlePage/ArticleContent/ArticleContent";
import formatPublishedDate from "@src/app/helper/formatPublishedDate";
import useArticle, { Article } from "@src/app/hooks/useArticle";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useRecommendedArticlesApi from "@src/app/hooks/useRecommendedArticlesApi";
import { ArticleCard } from "@src/app/types/Article";
import {
  // capitalizeFirstLetter,
  removeLeadingSlash,
  replaceSpecialChars,
} from "@src/app/utils/helpers";
import { logEnd, logStart } from "@src/app/utils/helpers";
import { useLocation, useParams } from "react-router-dom";

import { getAuthorNames, getTagNames } from "./helpers";

interface IArticlePageContent {
  article: Article;
  grapeshot: string;
  fr: string;
  popularThisMonth: ArticleCard[]; // Add this type definition
}

function ArticlePageContent({
  article,
  grapeshot,
  fr,
  popularThisMonth,
}: IArticlePageContent) {
  const { topic } = useParams();
  const { pathname } = useLocation();
  const timeStart = logStart();
  const { data: apiPayloadRecommendedArticles, isLoading } =
    useRecommendedArticlesApi({
      topic: topic || "",
      pathname,
    });
  logEnd("Article useRecommendedArticlesApi", timeStart);

  const recommendedArticles = apiPayloadRecommendedArticles?.data.results || [];

  const {
    id,
    headline,
    standfirst,
    heroImgUrl,
    mainVideoUrl,
    heroImgCaption,
    heroImgAlt,
    heroImgWidth,
    heroImgHeight,
    authors,
    translators,
    publishedDate,
    bodyElements,
    mainMedia,
    tags,
    category,
    displayType,
    seoFields,
    ogFields,
    ogImageUrl,
  } = article;

  const isArticleAComic = displayType == "comic";
  const isArticleVideo = displayType == "video";
  const isArticleBigRead = displayType == "big_read";
  const isArticlePhotoStory = displayType == "photo_story";

  const authorNames = getAuthorNames(authors);
  const tagNames = getTagNames(tags);

  const tagsNameArray = tags.map((tag) => tag.name);

  const targeting = [
    { key: "page", value: "article" },
    { key: "tctags", value: replaceSpecialChars(tagsNameArray.join(",")) },
    { key: "tcarticleid", value: id },
    { key: "gs_channels", value: grapeshot },
    { key: "fr", value: fr },
  ];

  const {
    isPrestitialEnabled,
    topOverlayImpressions,
    isCatfishEnabled,
    // isOutbrainEnabled,
  } = useGlobalAdSetting({ targeting });

  const isCulture = ["cartoon", "history", "culture"].includes(category);

  /* Newsletter Widget */
  const nlHeaderTitle = isCulture
    ? "Get the ThinkCulture Newsletter"
    : "Get the ThinkChina newsletter";
  const nlBodyText = isCulture
    ? "Your fortnightly dose of Chinese history, culture and cartoons. Sign up now."
    : "Insights on China, right in your mailbox. Sign up now.";
  const nlIsDark = isCulture ? false : true;
  const nlIsThinkCulture = isCulture;

  const articleContentProps: IArticleContent = {
    headline,
    date: publishedDate,
    authors,
    translators,
    category,
    displayType,
    mainVideoUrl,
    nlIsDark,
    nlIsThinkCulture,
    nlHeaderTitle,
    nlBodyText,
    standfirst,
    heroImgUrl,
    heroImgCaption,
    heroImgAlt,
    heroImgWidth,
    heroImgHeight,
    mainMedia,
    bodyElements,
    tags,
    recommendedArticles,
    recommendedArticlesIsLoading: isLoading,
    isArticleAComic,
    isArticleVideo,
    isArticleBigRead,
    isArticlePhotoStory,
    // isOutbrainEnabled,
    pathname,
    popularThisMonth, // Add this line to pass popular articles
  };

  const seoTitle = (seoFields?.metaTitle || "").trim();
  const seoDesc = (seoFields?.metaDescription || "").trim();
  const ogTitle = (ogFields?.ogTitle || "").trim();
  const ogDesc = (ogFields?.ogDescription || "").trim();

  // const displayedTopic = capitalizeFirstLetter(category);

  return (
    <>
      <AdSettings />

      <MetaTags
        title={seoTitle || headline}
        description={seoDesc || standfirst}
        ogType="website"
        slug={removeLeadingSlash(pathname)}
        image={heroImgUrl}
        keywords={tagsNameArray}
        author={authors[0]?.name}
        section={category}
        isArticlePage={true}
        ogTitle={ogTitle}
        ogDescription={ogDesc}
        ogImage={ogImageUrl}
        canonical={seoFields?.canonicalUrl}
      />

      <MetaTagsArticle
        headline={headline}
        standfirst={standfirst}
        heroImgUrl={heroImgUrl}
        authorName={authors[0]?.name || ""}
        authorUrl={authors[0]?.url || ""}
        createdAt={publishedDate}
      />
      <GAData
        grapeshot={grapeshot}
        title={headline}
        level2={category}
        contenttype="article"
        articleid={id}
        cue_articleid={id}
        author={authorNames}
        keyword={tagNames}
        pubdate={formatPublishedDate(publishedDate, "yyyy-MM-dd HH:mm:ss")}
      />

      <PrestitialAd
        slot={GoogleAdsSlotFactory.prestitial(category)}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />

      <ArticleContent {...articleContentProps} />

      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(category)}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}

export default function ArticlePage() {
  /* Outbrain */
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });

  const tResponse = useArticle();
  const statusCode = tResponse.statusCode;
  const payload = tResponse.payload;

  if (statusCode != 200 || !payload || typeof payload === "string") {
    return <NotFound />;
  }
  const article = payload.article || ({} as Article);
  const grapeshot = payload.grapeshot || ""; // string
  const fr = payload.fr || ""; // string
  const popularThisMonth = payload.popularThisMonth || [];

  console.log(article, "article");

  return (
    <ArticlePageContent
      article={article}
      grapeshot={grapeshot}
      fr={fr}
      popularThisMonth={popularThisMonth}
    />
  );
}
