import useLoadedTags from "@src/app/hooks/useLoadedTags/useLoadedTags";
import { AdTargetingType } from "@src/app/types/Ads.d";
import { useCallback, useEffect } from "react";

type GlobalAdSettingProps = {
  targeting?: AdTargetingType[];
};

export default function useGlobalAdSetting({
  targeting,
}: GlobalAdSettingProps = {}) {
  const {
    isPrestitialEnabled,
    topOverlayImpressions,
    isCatfishEnabled,
    isOutbrainEnabled,
  } = useLoadedTags();

  const handleGlobalAdsetting = useCallback(() => {
    googletag.cmd.push(function () {
      targeting &&
        targeting.forEach((target: AdTargetingType) => {
          googletag.pubads().setTargeting(target.key, target.value);
        });

      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
    });
  }, [targeting]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.googletag &&
      googletag.apiReady
    ) {
      handleGlobalAdsetting();
    }
  }, [handleGlobalAdsetting]);

  return {
    isPrestitialEnabled,
    topOverlayImpressions,
    isCatfishEnabled,
    /** @deprecated */
    isOutbrainEnabled,
  };
}
