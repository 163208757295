import { Helmet } from "react-helmet-async";

import {
  FORMAT_DETECTION,
  OG_SITE_NAME,
  TWITTER_CARD,
  URL_ORIGIN,
  URL_ORIGIN_ALTERNATE,
} from "./Constants";

export type MetaTagsProps = {
  title: string;
  description: string;
  slug: string;
  shortLink?: string;
  ogType?: string;
  /** @deprecated not in formal og */
  articlePageclass?: string;
  image?: string;
  /** @deprecated not used */
  publishedTime?: string;
  keywords?: string[];
  author?: string;
  section?: string;
  isArticlePage?: boolean;
  ogTitle?: string;
  ogDescription?: string;
  canonical?: string;
  ogImage?: string;
};

export default function MetaTags({
  title,
  description,
  slug,
  shortLink,
  ogType = "article",
  image,
  keywords = [],
  author,
  section,
  isArticlePage = false,
  ogTitle,
  ogDescription,
  canonical,
  ogImage,
}: MetaTagsProps) {
  const alternateUrl = URL_ORIGIN_ALTERNATE + slug;
  const canonicalUrl =
    canonical || `${URL_ORIGIN}${slug === "home" ? "" : slug}`;
  const shortLinkUrl = URL_ORIGIN + shortLink;
  // Sanitize the URL before using it in the tag
  const sanitizedUrl = canonicalUrl;
  // ? sanitizeUrl(canonicalUrl)
  // : sanitizeUrl(getWindowHref());

  // Facebook minumum dimension: 600 (w) x 315 (h) pixels (1.91:1)
  // LinkedIn minumum dimension: 1200 (w) x 627 (h) pixels (1.91:1)
  // Twitter minumum dimension: 300 (w) x 157 (h) pixels (2:1)
  // Optimal image dimensions for Facebook, LinkedIn & Linkin to ensure high-quality display on high-resolution screens.
  const metaImgMinWidth = 1200;
  const metaImgMinHeight = 627;

  const imgSuffix = isArticlePage
    ? `?w=${metaImgMinWidth}&h=${metaImgMinHeight}`
    : "";

  const _image =
    ogImage || image || `${import.meta.env.VITE_BASE_URL}/placeholder.svg`;

  console.log(_image, "ogImage, image");
  return (
    <Helmet>
      {/* Search Engine tags */}
      <title>{title || OG_SITE_NAME}</title>
      <meta name="robots" content="index, follow" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(", ")} />

      <link rel="alternate" href={alternateUrl} />
      <link rel="canonical" href={sanitizedUrl} />
      <link rel="shortlink" href={shortLinkUrl} />
      <meta name="format-detection" content={FORMAT_DETECTION} />

      {/* google-site-verification */}
      <meta
        name="google-site-verification"
        content="yE8InBB5xS9wFHBukGOmijn1jY0-jRkvCsmEcCjwBuc"
      />

      {/* Open Graph tags */}
      {/* refer to https://ogp.me/ */}
      {/* basic meta */}
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:type" content={ogType} />
      <meta property="og:image" content={`${_image}${imgSuffix}`} />
      <meta property="og:url" content={sanitizedUrl} />

      {/* optional meta */}
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:locale" content="en_SG" />
      <meta property="og:site_name" content={OG_SITE_NAME} />

      {/* <meta property="og:image:url" content={`${ogImage}${imgSuffix}`} />
      <meta property="og:image:secure" content={`${ogImage}${imgSuffix}`} /> */}

      {/* Article tags */}
      {isArticlePage && (
        <>
          <meta name="robots" content="max-image-preview:large" />
          <meta property="article:author" content={author} />
          <meta property="article:section" content={section} />
          {keywords.map((keyword, index) => (
            <meta key={index} property="article:tag" content={keyword} />
          ))}
          {/* <meta property="article:publisher" content={ARTICLE_PUBLISHER} /> */}
          {/* <meta name="article:pageclass" content={articlePageclass} /> */}
          {/* <meta property="article:published_time" content={publishedTime} /> */}
        </>
      )}

      {/* Twitter tags */}
      {/* refer to https://developer.x.com/en/docs/x-for-websites/cards/overview/markup */}
      <meta property="twitter:card" content={TWITTER_CARD} />
      <meta property="twitter:title" content={ogTitle || title} />
      <meta property="twitter:url" content={sanitizedUrl} />
      <meta
        property="twitter:description"
        content={ogDescription || description}
      />
      <meta property="twitter:image" content={`${_image}${imgSuffix}`} />
    </Helmet>
  );
}
