import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SPHLogo from "@src/app/assets/logo@mobile.svg";
import cx from "classnames";
import { MouseEvent, ReactElement } from "react";
import { useState } from "react";

import NavList from "./NavList";
import styles from "./NavMenu.module.css";
import NavMenuFooter from "./NavMenuFooter";

// function forceBfReload(event: PageTransitionEvent) {
//   console.log("GO");
//   if (
//     event.persisted ||
//     //@ts-expect-error Type not avail
//     performance.getEntriesByType("navigation")[0].type === "back_forward"
//   ) {
//     window.location.reload();
//   }
// }

export type Props = Readonly<{
  testId?: string;
}>;

export default function NavMenu({
  testId = "mobile-nav-menu",
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [fadeOutAnimation, setIsFadeOutAnimtion] = useState(false);

  function onNavMenuIconClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
    document.body.style.position = "fixed";
  }

  function onNavMenuModalCloseBtnClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsFadeOutAnimtion(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadeOutAnimtion(false);
      document.body.style.position = "";
    }, 250);
  }

  // useEffect(() => {
  //   window.addEventListener("pageshow", forceBfReload, false);

  //   return () => {
  //     window.removeEventListener("pageshow", forceBfReload, false);
  //   };
  // }, [setIsOpen]);

  return (
    <>
      <button
        onClick={onNavMenuIconClick}
        data-testid={`${testId}-navMenuIcon`}
        aria-label="Open Mobile Navigation Menu"
        className={cx(styles.navMenuIcon)}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      {isOpen && (
        <div
          data-testid={`${testId}-navMenuModal`}
          className={cx(styles.navMenuModal, fadeOutAnimation && styles.out)}
        >
          <div
            data-testid={`${testId}-navMenuModalHeader`}
            className={cx(styles.navMenuModalHeader)}
          >
            <div
              data-testid={`${testId}-imageLogoContainer`}
              className={cx(styles.imageLogoContainer)}
            >
              <img src={SPHLogo} alt="SPH Mobile Logo" />
            </div>
            <button
              onClick={onNavMenuModalCloseBtnClick}
              data-testid={`${testId}-navMenuModalCloseBtn`}
              className={cx(styles.navMenuModalCloseBtn)}
              aria-label="Close Navigation Menu"
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <NavList testId={testId} />
          <NavMenuFooter testId={testId} />
        </div>
      )}
    </>
  );
}
