import { useScript } from "@sphtech/web2-core/hooks";
import { adSlotSizes } from "@src/app/components/Elements/Advertisement/constants";
import betterads from "@src/app/components/Elements/Advertisement/scripts/betterads";
import betteradsHead from "@src/app/components/Elements/Advertisement/scripts/betteradsHead";
import betteradsHead2 from "@src/app/components/Elements/Advertisement/scripts/betteradsHead2";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { useEffect, useState } from "react";

export default function useLoadedTags() {
  const [isPrestitialEnabled, setIsPrestitialEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [topOverlayImpressions, setTopOverlayImpressions] = useState<
    number | undefined
  >(undefined);
  const [isCatfishEnabled, setIsCatfishEnabled] = useState<boolean | undefined>(
    undefined,
  );
  const [isOutbrainEnabled, setIsOutbrainEnabled] = useState<
    boolean | undefined
  >(undefined);

  useScript({
    id: "ads-checker-script",
    src: "https://adtag.sphdigital.com/tag/ads/ads_checker.js",
  });

  useScript({
    id: "suid-script",
    src: "https://cdn.sphlabs.com/suid/suid.min.js",
  });

  useEffect(() => {
    // Make sure that googletag.cmd exists.
    window.googletag = (window.googletag as typeof googletag | undefined) || {};
    ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
      ((window.googletag as typeof googletag).cmd as
        | googletag.CommandArray
        | undefined) || [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    window.googletag.slots = window.googletag.slots || {};

    // Correct: Queueing the callback on the command queue.
    googletag.cmd.push(function () {
      const dfpTagsObj = googletag
        .pubads()
        .getSlots()
        .map((_slot) => {
          const id = _slot.getSlotElementId().replaceAll("dfp-ad-", "");

          const size = Object.keys(
            adSlotSizes[id] as googletag.GeneralSize,
          ).map((_, index) => {
            const sizes = adSlotSizes[id][index];

            return sizes.toString().replaceAll(",", "x");
          });

          const loadedDfpTag = {
            [id]: {
              adunit: _slot.getAdUnitPath(),
              slotname: _slot.getSlotElementId(),
              size: size.join(","),
            },
          };

          // Added googletag.slots so ads team can feed from it.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots[
            _slot.getSlotElementId().replaceAll("dfp-ad-", "")
          ] = _slot;

          return loadedDfpTag;
        });

      const loadedDfpTags: Partial<Record<AdvertisementTypeEnum, object>> = {};

      dfpTagsObj.forEach(
        (item: Partial<Record<AdvertisementTypeEnum, object>>) => {
          const key = Object.keys(item).at(0) as
            | AdvertisementTypeEnum
            | undefined;

          key && (loadedDfpTags[key] = item[key]);
        },
      );

      if (Object.keys(loadedDfpTags).length > 0) {
        // Load dfpTagsScript.
        const loadDfpTagsScriptId = "loadDfpTagsScript";
        if (!document.getElementById(loadDfpTagsScriptId)) {
          const loadDfpTagsScript = document.createElement("script");

          loadDfpTagsScript.id = loadDfpTagsScriptId;
          loadDfpTagsScript.dataset.testid = loadDfpTagsScriptId;
          loadDfpTagsScript.innerHTML = `var loaded_dfp_tags = '${JSON.stringify(
            loadedDfpTags,
          )}';`;
          loadDfpTagsScript.type = "text/javascript";
          document.head.appendChild(loadDfpTagsScript);
        }

        // Load betteradsHeadScript.
        const loadBetteradsHeadScriptId = "betteradsHeadScript";
        if (!document.getElementById(loadBetteradsHeadScriptId)) {
          const loadBetteradsHeadScript = document.createElement("script");

          loadBetteradsHeadScript.id = loadBetteradsHeadScriptId;
          loadBetteradsHeadScript.dataset.testid = loadBetteradsHeadScriptId;
          loadBetteradsHeadScript.innerHTML = betteradsHead;
          loadBetteradsHeadScript.type = "text/javascript";
          document.head.appendChild(loadBetteradsHeadScript);

          // set outbrain
          // if (window.outbrain_enable == 1) {
          //   console.log("Outbrain is enabled");
          //   setIsOutbrainEnabled(true);
          // }
        }

        // Load smx_prebid.js
        /** @deprecated */
        /** load script in AdSetting */
        const loadSmxPrebidScriptId = "loadSmxPrebidScript";
        if (!document.getElementById(loadSmxPrebidScriptId)) {
          const loadSmxPrebidScript = document.createElement("script");

          loadSmxPrebidScript.id = loadSmxPrebidScriptId;
          loadSmxPrebidScript.dataset.testid = loadSmxPrebidScriptId;
          loadSmxPrebidScript.async = true;
          loadSmxPrebidScript.src =
            "https://adtag.sphdigital.com/tag/smx/sites/tc/smx_prebid.js";
          loadSmxPrebidScript.onload = () => {
            if (typeof window === "undefined") return;
            setIsPrestitialEnabled(window.prestitial_enabled === 1);
            setTopOverlayImpressions(window.topOverlayImpressions as number);
            setIsCatfishEnabled(window.catfish_enabled === 1);
            /** @deprecated fetch outbrain_enable only in article page */
            setIsOutbrainEnabled(window.outbrain_enable === 1);

            // Load betteradsHead2 when smx_prebid is loaded.
            const loadBetteradsHead2ScriptId = "betteradsHead2Script";
            if (!document.getElementById(loadBetteradsHead2ScriptId)) {
              const loadBetteradsHead2Script = document.createElement("script");

              loadBetteradsHead2Script.id = loadBetteradsHead2ScriptId;
              loadBetteradsHead2Script.dataset.testid =
                loadBetteradsHead2ScriptId;
              loadBetteradsHead2Script.innerHTML = betteradsHead2;
              loadBetteradsHead2Script.type = "text/javascript";
              document.body.appendChild(loadBetteradsHead2Script);
            }
          };
          document.head.appendChild(loadSmxPrebidScript);
        }

        // Load betteradsScript.
        const loadBetteradsScriptId = "betteradsScript";
        if (!document.getElementById(loadBetteradsScriptId)) {
          const loadBetteradsScript = document.createElement("script");

          loadBetteradsScript.id = loadBetteradsScriptId;
          loadBetteradsScript.dataset.testid = loadBetteradsScriptId;
          loadBetteradsScript.innerHTML = betterads;
          loadBetteradsScript.type = "text/javascript";
          document.head.appendChild(loadBetteradsScript);
        }
      }
    });
  }, []);

  return {
    isPrestitialEnabled,
    topOverlayImpressions,
    isCatfishEnabled,
    /** @deprecated */
    isOutbrainEnabled,
  };
}
